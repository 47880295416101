import React, { useEffect } from 'react';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { IS_DEV } = publicRuntimeConfig;

const Zendesk: React.FC = () => {
  useEffect(() => {
    window.location.href = `https://${
      IS_DEV ? 'd3v-lifegoeson2119' : 'laundrygozen'
    }.zendesk.com/hc/ko/p/lgbridge?sessionExpiredYn=Y`;
  }, []);

  return <div />;
};

export default Zendesk;
